import { Injectable } from "@angular/core";
import { ISorting } from "src/app/model/sorting";
import { CListService } from "src/app/common/services/_list.service";

@Injectable()
export class CGalsListService extends CListService {
    public override sorting: ISorting = {by: "name", dir: 1};
    public override filter = {    
        id: null,    
        name: "", 
    };

    public override filterChanged(): boolean {
        return this.filter.id !== null || this.filter.name !== "";
    }

    public override filterReset(): void {
        this.filter = {
            id: null,
            name: "",            
        };
    }  
}
